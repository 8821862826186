.createButton {
    margin: 20px;
}

.filter {
    
}

.allTournaments {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}